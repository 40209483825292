import React from 'react'
import Contact from '../../Components/GetinTouch/Contact'
import Brand from '../../Components/Brands/Brand'
import Meet from "../../Assets/Meeting.png";
import Analise from "../../Assets/Analise.png";
import Currency from "../../Assets/Currency.png";
import HalfCircle from "../../Assets/HalfCircle.png";
import { FaEnvelope, FaFacebook, FaInstagram, FaLocationArrow, FaPhoneAlt, FaYoutube } from 'react-icons/fa';


const Wantjoin = () => {
  return (
    <>
<section>
        <div className="container-fluid">
         
            <div className="heroimg">
              <div className="overlay">
                <h2>WANT TO JOIN</h2>
              </div>
            </div>
          </div>
        
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="servicehead py-4">
              <h1>
                BE OUR  <span> DESIGN EXPERT</span>
              </h1>
              <p>
                We at iHome Masters provide services ranging from interior
                design, woodwork, furniture, home lighting, designer ceilings,
                wall painting and wallpaper and any type of civil works related
                to residential & commercial.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row">
            <div className="col-md-6">
              <div className="workimg">
                <img src={Meet} alt="Meet" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="workimgtitle">
                <h1>MEET CLIENTS</h1>
                <p>
                At the heart of every successful project is a strong partnership with our clients. Meet Clients is more than just a consultation—it's an opportunity for us to understand your unique needs, preferences, and vision. We believe in open communication, listening carefully to your ideas, and providing expert advice to help shape your perfect space.

Building trust and understanding your goals is key to creating designs that truly reflect who you are.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row">
            <div className="col-md-6">
              <div className="workimgtitle1">
                <h1>PREPARE FINAL 3D DESIGN</h1>
                <p>
                The Prepare Final 3D Design phase is where your vision comes to life in stunning detail. Using advanced 3D modeling software, we create a realistic, interactive representation of your space, allowing you to experience the design before construction even begins.

This step helps ensure that every element—color schemes, furniture placement, lighting, and textures—aligns perfectly with your expectations. It’s your chance to make any final tweaks before the work starts.                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="workimg">
                <img src={Analise} alt="Analise" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row">
            <div className="col-md-6">
              <div className="workimg">
                <img src={Currency} alt="Currency" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="workimgtitle">
                <h1>EARN ON EVERY PROJECT</h1>
                <p>
                Become a part of our <b>iHomeMaster</b> family and start earning with every project you contribute to. Whether you're a designer, contractor, or skilled tradesperson, we offer exciting opportunities to work on a variety of projects. With every successful collaboration, you’ll earn competitive compensation, while enhancing your portfolio with amazing designs and builds.                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row">
            <div className="contacttitle py-4">
              <h3>CONNECT US</h3>
              <p>Fill the form for joining enquiry.</p>
            </div>

            <div className="col-md-6 imgsection">
              <div className="bgblack">
                <div className="imagedata py-4">
                  <h2>Contact Information</h2>
                  <p>Say something to start a live chat!</p>
                </div>
                <div className="imgcontactdetail">
                  <div className="phonedetail py-4">
                    <FaPhoneAlt />
                    <a
                      target="_blank"
                      href="tel:+919971873193"
                      aria-label="Phone"
                    >
                      +91 9971873193
                    </a>
                  </div>
                  <div className="maildetail py-4">
                    <FaEnvelope />
                    <a
                      target="_blank"
                      href="mailto:interiors@ihomemasters.com"
                      aria-label="mail"
                    >
                      interiors@ihomemasters.com
                    </a>
                  </div>
                  <div className="locationdetail py-4">
                    <FaLocationArrow />
                    <a target="_blank" href="#" aria-label="location">
                      Tower-9th floor, office no.-9131, Gaur city mall, Noida Extension, 201016
                    </a>
                  </div>
                </div>
                <div className="imageIcon py-5">
                  <a target="_blank" href="https://www.facebook.com/ihomemasters" aria-label="Facebook">
                    <FaFacebook />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/ihomemasters/" aria-label="Facebook">
                    <FaInstagram />
                  </a>
                  <a target="_blank" href="https://youtube.com/@ihomemasters?si=Uc5ZABtWUEpNu3pT" aria-label="Youtube">
                  <FaYoutube />
                  </a>
                </div>
                <div className="circleimg">
                  <img src={HalfCircle} alt="" />
                </div>
              </div>
            </div>

            {/* Form */}

            <div className="col-md-6">
              <form className="contact-form pt-4">
                <div className="row">
                  <div className="col-md-6 mb-3 cuscol">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="firstName"
                        placeholder=""
                        required
                      />
                      <label htmlFor="firstName" className="form-label">
                        Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 cuscol">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control custom-input"
                        id="lastName"
                        placeholder=""
                        required
                      />
                      <label htmlFor="lastName" className="form-label">
                        Age
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control custom-input"
                      id="email"
                      placeholder=""
                      required
                    />
                    <label htmlFor="email" className="form-label">
                      Address
                    </label>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control custom-input"
                      id="phone"
                      name="phone"
                      placeholder=""
                      maxLength="10"
                      pattern="[0-9]{10}" // Regex for exactly 10 digits
                      title="Phone number must be 10 digits."
                      required
                    />
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="select mb-3">Expert In </label>
                  <div className="subject-options">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="subject"
                        id="subject1"
                        required
                      />
                      <label className="form-check-label" htmlFor="subject1">
                        General Inquiry
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="subject"
                        id="subject1"
                        required
                      />
                      <label className="form-check-label" htmlFor="subject1">
                        Modular Kitchen
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="subject"
                        id="subject1"
                        required
                      />
                      <label className="form-check-label" htmlFor="subject1">
                        Modular Wardrobe
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="subject"
                        id="subject1"
                        required
                      />
                      <label className="form-check-label" htmlFor="subject1">
                        Modular Furniture
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="form-group">
                    <textarea
                      className="form-control custom-input"
                      id="message"
                      rows="3"
                      placeholder=""
                      required
                    ></textarea>
                    <label htmlFor="message" className="form-label">
                    Tell us about yourselft ex- site done, work experience etc...
                    </label>
                  </div>
                </div>

                <div className="d-grid">
                  <button type="submit" className="btn btn-primary">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </section>
      <section>
      <Brand />
      </section>

    </>
  )
}

export default Wantjoin